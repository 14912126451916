import React, { useEffect } from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as Markdown from 'react-markdown'
import colors from '../styles/colors'
import Button from './atoms/Button'
import Usa from '../assets/icons/usa'
import Hardhat from '../assets/icons/hard-hat'
import Boxusd from '../assets/icons/boxusd'
import HeroRatingStars from './hero-rating-stars'
import Slider from 'react-slick'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { motion } from "framer-motion";
import ReviewTilesComponent from './ReviewTilesComponent'

const SliderContainer = styled.div`
  &:hover {
    .PrevNextArrowsContainer {
      opacity: 1 !important;
    }
  }
  .PrevNextArrowsContainer {
    opacity: 0.1;
    background-color: rgba(204, 204, 204, 0.4);
    &:hover {
      background-color: rgba(204, 204, 204, 0.9);
    }
  }
  &.server-rendered {
    .slick-track {
      left: 0% !important;
    }
  }
`
const PrevNextArrowsContainer = styled.div`
  position: absolute;
  z-index: 1;
  @media (max-width: 767px) {
    top: calc(50% - 15px);
    height: 30px;
    width: 30px;
    padding: 5px;
  }
  top: calc(50% - 25px);
  height: 50px;
  width: 50px;

  transition: 0.2s ease all;
  color: #333;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  &.carousel-icon-right {
    right: 18px;
  }
  &.carousel-icon-left {
    left: 18px;
  }
`


const NextArrow = (props) => {
  const { onClick } = props

  //auto scroll
  //setInterval(() => onClick(), 10000)

  return (
    <PrevNextArrowsContainer
      className="icon-container carousel-icon-left PrevNextArrowsContainer"
      onClick={onClick}
    >
      <ChevronLeft size={26} />
    </PrevNextArrowsContainer>
  )
}

const PrevArrow = (props) => {
  const { onClick } = props
  return (
    <PrevNextArrowsContainer
      className="icon-container carousel-icon-right PrevNextArrowsContainer"
      onClick={onClick}
    >
      <ChevronRight size={26} />
    </PrevNextArrowsContainer>
  )
}

const settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 7000,
  pauseOnHover: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  cssEase: 'linear',
  className: 'disable-interaction-on-non-active relative',
  nextArrow: <PrevArrow />,
  prevArrow: <NextArrow />,
}

const HeroContent = ({
  heroTitle,
  heroImage,
  description,
  primaryActionBtnLabel,
  primaryActionBtnLink,
  style,
  alt,
  preloadImage,
  hideImage,
}) => (

  <div className='max-w-8xl mx-auto'>
    <section className="bg-white overflow-hidden relative">
      {/* <div className='Block-background HeroImageBlock-background u-bgColor--transparentOrange absolute right-0 top-0 bottom-20 bg-brand-50' style={{ zIndex: 0, width: '60%' }} /> */}

      <GatsbyImage
        image={getImage(heroImage)}
        alt={alt}

        loading={preloadImage}
        className="absolute top-0 left-0 w-full max-w-none h-full absolute-important h-full"
      />      <div className='absolute  top-0 left-0 w-full h-full opacity-75 bg-gradient-to-b from-brand-700 to-transparent md:h-[200px]' />

      <div className='absolute  bottom-0 left-0 w-full h-full opacity-50 bg-gradient-to-t md:bg-gradient-to-r from-brand-500 to-transparent  ' />
      <div className='max-w-7xl flex  justify-end  items-end   mx-auto heroImageBlock-home-container z-1  min-h-[85vh]' >

        <motion.div className='w-full px-6 md:px-8 mb-12 md:mb-0 py-16 md:py-32 shrink-0 z-1 relative  '

          initial={{ opacity: 0, y: 7 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: .35 }, }}
          staggerChildren={.8}
          viewport={{ once: true }}>
          <div className='flex   flex-col justify-end max-w-2xl min-h-[40vh] pt-40'>
            <span className='hidden text-accent-400 mb-2 block font-semibold md:text-xl'>Seattle Renovation Group</span>
            <h1 className='font-display font-extrabold text-4xl md:text-5xl text-white '>{heroTitle}</h1>
            <p className=' mb-4 pt-4 text-base md:text-lg text-white md:leading-7 [text-shadow:_0_0_7px_rgb(0_0_0_/_25%)]'>

              {description && description.description && (
                <Markdown
                  className="feature-extended-image has-animation"
                  children={description.description}
                />
              )}

            </p>


            <motion.div><Button to='/services/'  >Learn More</Button></motion.div></div>


          {/* <motion.div className='w-full   backdrop-blur-2xl bg-white/60  z-1 relative  mt-12 rounded-xl p-2'>
            <ReviewTilesComponent />
          </motion.div> */}

        </motion.div>

        <div className='absolute bottom-0 w-full left-0 bg-white/60 backdrop-blur-sm	' >
          <div className='max-w-7xl mx-auto px-4'>
            <HeroRatingStars theme="dark" classes="  font-display font-medium   text-sm  shadow-xl   rounded-full px-4  py-4 " />
          </div>
        </div>
      </div>
    </section >
  </div >
)

const HeroArea = ({ heroContent }) => {

  let isMultiSlide = false

  if (heroContent.length || heroContent.length > 2) {
    isMultiSlide = true
  }

  const isBuildingHTML = typeof window === 'undefined'


  if (Array.isArray(heroContent)) {
    heroContent = heroContent[0]
  }

  // return <HeroContent {...heroContent} preloadImage />

  if (Array.isArray(heroContent)) {
    return (
      <SliderContainer className={isBuildingHTML ? 'server-rendered' : null}>
        <Slider {...settings}>
          {heroContent.map((item, index) => (
            <HeroContent
              {...item}
              alt={item.heroTitle}
              key={index}
              preloadImage={index == 0}
            />
          ))}
        </Slider>
      </SliderContainer>
    )
  } else {
    return <HeroContent {...heroContent} preloadImage />
  }
}

export default HeroArea
